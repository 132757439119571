var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aw-inspirations wrapper"},[_c('div',{staticClass:"aw-inspirations__heading pb-m mb-xl"},[_c('GTitle',{staticClass:"aw-inspirations__heading__title h1",attrs:{"content":_vm.content.title,"tag":"h2"}}),_c('div',{staticClass:"aw-inspirations__heading__text",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}})],1),(_vm.content.items)?_c('UiMasonry',{ref:"masonryRef",attrs:{"items":_vm.content.items.slice(0, _vm.numberToDisplayRef),"keyProp":"src"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card-inspirations"},[(item.link)?_c('div',{staticClass:"card-inspiration-btn"},[_c('GAction',{staticClass:"card-inspirations-button",attrs:{"content":{
              url: item.link.url,
              label: item.link.label,
              modifiers: ['btn', 'filled'],
              icon: 'arrow',
            }}})],1):_vm._e(),_c('UiPicture',{staticClass:"card-inspirations-profile",attrs:{"content":item.profilePicture,"sets":['160'],"cover":true}}),_c('div',{staticClass:"card-inspirations-picture"},[_c('UiPicture',{staticClass:"inspirations-picture-front aw-inspirations__item",attrs:{"content":item.frontPicture,"sets":['160', '240', '320', '480', '640'],"cover":true,"sizes":"(min-width: 960px) 640w, (min-width: 480px) 50vw, 100vw"}}),_c('UiPicture',{staticClass:"inspirations-picture-back aw-inspirations__item",attrs:{"content":item.backPicture,"sets":['160', '240', '320', '480', '640'],"cover":true,"sizes":"(min-width: 960px) 640w, (min-width: 480px) 50vw, 100vw"}})],1)],1)]}}],null,false,3754139194)}):_vm._e(),(_vm.numberToDisplayRef <= _vm.content.items.length)?_c('GAction',{staticClass:"aw-inspirations__button mt-m",class:{ 'is-hidden': _vm.numberToDisplayRef >= _vm.content.items.length },attrs:{"content":{
      tag: 'button',
      label: _vm.content.seeMore,
      modifiers: ['btn'],
    }},nativeOn:{"click":function($event){return _vm.onSeeMore.apply(null, arguments)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }






















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import AWCta from '@/components/cta/Moodboard.vue'
import AWInspirations from '@/components/anders-wonen/Inspirations.vue'
import AWMoods from '@/components/anders-wonen/Moods.vue'

export default defineComponent({
  name: 'AndersWonenArchive',
  components: { AWCta, AWInspirations, AWMoods },
  props: {},

  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})












import { PropType, defineComponent } from '@vue/composition-api'

import UiMasonry from '@/components/ui/Masonry.vue'
import AWMood, { Mood } from '@/components/anders-wonen/Mood.vue'

interface Moods {
  items: Mood[]
}

export default defineComponent({
  name: 'AWMoods',
  components: {
    UiMasonry,
    AWMood,
  },
  props: {
    content: {
      type: Object as PropType<Moods>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})















































































import type { Link, Picture } from '@/inc/types'

import { PropType, ref, defineComponent } from '@vue/composition-api'

import UiMasonry from '@/components/ui/Masonry.vue'
import UiPicture from '@/components/ui/Picture.vue'

interface Inspirations {
  title: string
  htmltext: string
  items: {
    frontPicture: Picture
    backPicture: Picture
    profilePicture: Picture
    link: Link
  }[]
  seeMore: string
}

export default defineComponent({
  name: 'AWInspirations',
  components: {
    UiMasonry,
    UiPicture,
  },
  props: {
    content: {
      type: Object as PropType<Inspirations>,
      required: true,
    },
  },

  setup() {
    const masonryRef = ref<InstanceType<typeof UiMasonry> | null>(null)
    const numberToDisplayRef = ref(6)

    // Display more inspirations
    const onSeeMore = () => {
      numberToDisplayRef.value += 6

      setTimeout(() => {
        masonryRef.value?.refreshLayout()
      }, 100)
    }

    return {
      masonryRef,
      numberToDisplayRef,
      onSeeMore,
    }
  },
})
